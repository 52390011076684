import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <nav className="level">
        <div className="level-left">
          <div className="level-item has-text-white">© {new Date().getFullYear()}, Clavicon GmbH</div>
        </div>

        <div className="level-right">
          <Link className="level-item has-text-white" to="/legal">
            Legal
          </Link>
          <Link className="level-item has-text-white" to="/privacy">
            Privacy
          </Link>
          <Link className="level-item has-text-white" to="/contact">
            Contact
          </Link>
        </div>
      </nav>
    </div>
  </footer>
)

export default Footer

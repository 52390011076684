import * as React from "react"
import { Link } from "gatsby"
import imgLogo from "../../images/logo.svg"

import "./_navbar.scss"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: "",
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            })
      }
    )
  }

  render() {
    return (
      <div className="topnav" id="topnav">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item pl-0 ml-0" to="/">
              <img
                className=""
                src={imgLogo}
                alt="Logo Clavicon"
                width="300"
                height="40"                
              />
            </Link>
            <Link
              role="button"
              class="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarTop"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </Link>
          </div>
          <div id="navbarTop" class="navbar-menu">
            <div class="navbar-end">
              <Link className="navbar-item" to="/about">
              خبرتنا
              </Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to="/corporate-security">
                سلامتك
                </Link>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/corporate-security">أمن الشركات</Link>
                  <Link className="navbar-item" to="/private-security">الأمن الخاص</Link>
                  <Link className="navbar-item" to="/information-security">الأمن المعلوماتي</Link>
                  <Link className="navbar-item" to="/training">البرامج التدريبية</Link>                  
                </div>
              </div>

              <div className="lang">
                <a className="navbar-item" href="https://www.clavicon.com">
                  de
                </a>
                <a className="navbar-item" href="https://en.clavicon.com">
                  en
                </a>
                <a className="navbar-item" href="https://ar.clavicon.com">
                  ar
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default Navbar

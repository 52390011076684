import React from "react"
import Navbar from "../navbar"

import "./_header.scss"

const Header = () => (
  <header>
    <div className="container">
      <Navbar />
    </div>
  </header>
)

export default Header
